<template>
  <div class="page-container role-add">
    <div class="page-header">
      <ace-header show-back>
        {{ $t('system.charge.addRole') }}
      </ace-header>
    </div>
    <div class="page-body pt-20px">
      <van-form
        v-if="!showRoleInfo"
        validate-first
        :show-error-message="false"
        validate-trigger="onSubmit"
        @submit="onSubmit"
        @failed="onFailed"
      >
        <div class="mx-4px">
          <van-field
            readonly
            clickable
            :value="selectedServerName"
            autocomplete="off"
            name="server_id"
            :placeholder="$t('system.charge.server')"
            :rules="[{ required: true, message: $t('system.charge.serverIdHolder') }]"
            class="mt-12px"
            @click="onPickerShow"
          >
            <template #right-icon>
              <van-icon name="arrow-down" color="#efefef" />
            </template>
          </van-field>
          <van-field
            v-model="playerId"
            clearable
            maxlength="24"
            autocomplete="off"
            name="player_id"
            :placeholder="$t('system.charge.roleID')"
            :rules="[{ required: true, message: $t('system.charge.roleIDHolder') }]"
            class="mt-12px"
          />
          <div class="mt-30px mx-14px">
            <van-button
              block
              type="primary"
              :disabled="disabled"
              :loading="searchLoading"
              native-type="submit"
            >
              {{ $t('common.queryText') }}
            </van-button>
          </div>
        </div>
      </van-form>
      <div v-if="showRoleInfo" class="px-10px">
        <role-item :info="roleData" :show-action="false" class="mb-30px" />
        <div class="flex justify-between">
          <van-button
            :text="$t('system.charge.searchAgain')"
            class="w-160px"
            @click="showRoleInfo = false"
          />
          <van-button
            type="primary"
            :text="$t('common.saveText')"
            class="w-160px"
            :loading="saveLoading"
            @click="onRoleSave"
          />
        </div>
      </div>
      <van-popup
        v-model="showServerPicker"
        position="bottom"
        class="overflow-y-hidden"
        @opened="onPickerOpened"
      >
        <div class="pt-10px w-full">
          <div class="text-center">{{ $t('system.charge.server') }}</div>
          <van-field
            v-model="serverName"
            clearable
            maxlength="24"
            :placeholder="$t('system.charge.serverIdHolder')"
            class="border-bottom-1"
          >
            <template #button>
              <van-button size="small" type="primary" @click="onServerFilter">
                {{ $t('common.searchText') }}
              </van-button>
            </template>
          </van-field>
        </div>
        <div class="h-480px overflow-y-auto">
          <van-radio-group v-model="selectedServerId" @change="onServerSelected">
            <van-cell-group>
              <van-list v-model="loading" :finished="finished" @load="onLoad">
                <van-cell
                  v-for="item in serverListFilter"
                  :key="item.value"
                  :title="item.text"
                  clickable
                  @click="onServerSelected(item.value)"
                >
                  <template #right-icon>
                    <van-radio :name="item.value" checked-color="#ffd461" />
                  </template>
                </van-cell>
              </van-list>
            </van-cell-group>
          </van-radio-group>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import { mapGetters } from 'vuex';
  import { storage } from '@/utils/storage';
  import { cloneDeep } from 'lodash-es';
  import { getServerList, getRole, addRole } from '@/api/system';
  import RoleItem from '@/components/RoleItem.vue';

  export default {
    name: 'RoleList',
    components: { RoleItem },
    data() {
      return {
        loading: false,
        finished: false,
        serverList: [],
        serverListCopy: [],
        serverCount: 0,
        serverName: '',
        serverListFilter: [],
        showServerPicker: false,
        selectedServerId: '',
        selectedServerName: '',
        playerId: '',
        disabled: true,
        searchLoading: false,
        showRoleInfo: false,
        roleData: {
          level: '',
          nick_name: '',
          player_id: '',
          server_id: '',
        },
        saveLoading: false,
      };
    },
    computed: {
      ...mapGetters({
        defaultGame: 'defaultGame',
      }),
      selectedGame() {
        return storage.get('SELECTED_PRODUCT_GAME') || this.defaultGame;
      },
    },
    methods: {
      async getServerData() {
        try {
          const app_uid = this.selectedGame.app_uid;
          const res = await getServerList(app_uid);
          this.serverCount = res.length;
          this.serverList = res.map((item) => ({
            text: item.serverName,
            value: String(item.serverId),
          }));
          this.serverListCopy = cloneDeep(this.serverList);
          this.serverListFilter = this.serverListCopy.splice(0, 30);
          this.selectedServerName = res[0].serverName;
          this.selectedServerId = String(res[0].serverId);
        } catch (err) {
          return err;
        }
      },
      onPickerShow() {
        this.showServerPicker = true;
        this.$toast.loading({
          message: this.$t('common.loadingText'),
          duration: 0,
        });
      },
      onPickerOpened() {
        this.$toast.clear();
      },
      onLoad() {
        setTimeout(() => {
          const sliceData = this.serverListCopy.splice(0, 30);
          this.serverListFilter = this.serverListFilter.concat(sliceData);
          // 加载状态结束
          this.loading = false;
          // 数据全部加载完成
          if (this.serverListFilter.length >= this.serverCount) {
            this.finished = true;
          }
        }, 1000);
      },
      onServerFilter() {
        if (!this.serverName) return;
        const searchStr = this.serverName.toLowerCase();
        this.serverListFilter = this.serverList.filter((item) =>
          item.text.toLowerCase().includes(searchStr)
        );
      },
      onServerSelected(value) {
        const server = this.serverList.find((item) => item.value === value);
        this.selectedServerId = server.value;
        this.selectedServerName = server.text;
        this.showServerPicker = false;
      },
      async onSubmit(values) {
        try {
          this.searchLoading = true;
          const res = await getRole({
            server_id: this.selectedServerId,
            server_name: this.selectedServerName,
            player_id: values.player_id,
            app_uid: this.selectedGame.app_uid,
          });
          if (res) {
            this.showRoleInfo = true;
            this.roleData = { ...res, app_name: this.defaultGame.app_name };
          }
        } finally {
          this.searchLoading = false;
        }
      },
      onFailed({ errors }) {
        this.$toast(errors[0].message);
      },
      async onRoleSave() {
        try {
          this.saveLoading = true;
          const data = {
            server_id: this.selectedServerId,
            player_id: this.playerId,
            nick_name: this.roleData.nick_name,
            level: this.roleData.level,
            app_uid: this.defaultGame.app_uid,
          };
          const res = await addRole(data);
          if (res) {
            this.$router.replace({ name: 'role-list' });
          }
        } finally {
          this.saveLoading = false;
        }
      },
    },
    beforeMount() {
      this.getServerData();
    },
    watch: {
      serverName(val) {
        if (!val) {
          this.serverListFilter = this.serverList.slice(0, this.serverListFilter.length);
        }
      },
      playerId(val) {
        if (val) {
          this.disabled = false;
        } else {
          this.disabled = true;
        }
      },
    },
  };
</script>
